<!--
* @description 数字化建设管理平台
* @fileName szhjsgl.vue
* @author liulian
* @date 2023/06/29 16:33:22
-->
<template>
  <div class="szhjsgl">
    <div class="z_banner">
      <img class="z_b_img" :src="$t(`productSzhjsgl.banner`)" alt="banner" />
    </div>
    <div class="ptjs">
      <div class="p_con">
        <div class="z_title text-align-center font-size-36 font-weight-500 font-color-black-1D1D1F">{{ $t("productSzhjsgl.section1_title") }}</div>
        <div class="p_descript font-size-18 font-weight-400">{{ $t("productSzhjsgl.section1_content") }}</div>
        <div class="z_title text-align-center font-size-36 font-weight-500 font-color-black-1D1D1F">{{ $t("productSzhjsgl.section2_title") }}</div>
        <!-- 典型工程 -->
        <div class="p_dxgc flex-row-between">
          <div class="p_d_item" v-for="(item,index) in dxgcs" :key="index">
            <img class="p_d_i_img" :src="item.cover" alt="封面图">
          </div>
        </div>
        <div class="z_title text-align-center font-size-36 font-weight-500 font-color-black-1D1D1F">{{ $t("productSzhjsgl.section3_title") }}</div>
        <div class="p_hxnl flex-row-between flex-wrap">
          <div class="p_h_item flex-row align-center" v-for="(item, index) in hxnls" :key="index">
            <div class="p_h_i_left">
              <img class="p_h_i_left_img" :src="require(`@/assets/images/productImg/hxnl_icon_${index + 1}@2x.png`)" alt="图标" />
            </div>
            <div class="p_h_i_right">
              <div class="p_h_i_r_name font-size-22 font-weight-400 font-color-black-1D1D1F">{{ $t(`productSzhjsgl.${item.name}`) }}</div>
              <div class="p_h_i_r_con font-size-14 font-weight-400">
                {{ $t(`productSzhjsgl.${item.con1}`) }}
                <br/>
                {{ $t(`productSzhjsgl.${item.con2}`) }}
                <br/>
                {{ $t(`productSzhjsgl.${item.con3}`) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 平台亮点 -->
    <div class="p_ptld">
      <div class="z_title text-align-center font-size-36 font-weight-500 font-color-black-1D1D1F">{{ $t("productSzhjsgl.section4_title") }}</div>
      <div class="p_p_con flex-row-between flex-wrap">
        <div class="p_p_c_item" v-for="(item, index) in ptlds" :key="index">
          <div class="p_h_i_right">
            <img class="p_p_c_item_img" :src="$t(`productSzhjsgl.${item.default}`)" alt="icon" />
          </div>
          <!-- 反转后背景图 -->
          <div class="p_p_c_item_hover">
            <img class="p_p_c_item_hover_img" :src="$t(`productSzhjsgl.${item.cover}`)" alt="封面图" />
            <!-- <div class="p_hover_title font-size-24 font-weight-600 font-color-white-ffffff">
              {{ item.name }}
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "szhjsgl",
  components: {},
  data() {
    return {
      hxnls: [
        {
          name: "section3_tab1_title",
          con1: "section3_tab1_content1",
          con2: "section3_tab1_content2",
          con3: "section3_tab1_content3",
        },
        {
          name: "section3_tab2_title",
          con1: "section3_tab2_content1",
          con2: "section3_tab2_content2",
          con3: "section3_tab2_content3",
        },
        {
          name: "section3_tab3_title",
          con1: "section3_tab3_content1",
          con2: "section3_tab3_content2",
          con3: "section3_tab3_content3",
        },
        {
          name: "section3_tab4_title",
          con1: "section3_tab4_content1",
          con2: "section3_tab4_content2",
          con3: "section3_tab4_content3",
        },
        {
          name: "section3_tab5_title",
          con1: "section3_tab5_content1",
          con2: "section3_tab5_content2",
          con3: "section3_tab5_content3",
        },
        {
          name: "section3_tab6_title",
          con1: "section3_tab6_content1",
          con2: "section3_tab6_content2",
          con3: "section3_tab6_content3",
        },
      ],
      ptlds: [
        {
          default: "ptlds_default1",
          cover: "ptlds_cover1",
        },
        {
          default: "ptlds_default2",
          cover: "ptlds_cover2",
        },
        {
          default: "ptlds_default3",
          cover: "ptlds_cover3",
        },
        {
          default: "ptlds_default4",
          cover: "ptlds_cover4",
        },
        {
          default: "ptlds_default5",
          cover: "ptlds_cover5",
        },
        {
          default: "ptlds_default6",
          cover: "ptlds_cover6",
        },
        {
          default: "ptlds_default7",
          cover: "ptlds_cover7",
        },
        {
          default: "ptlds_default8",
          cover: "ptlds_cover8",
        },
        {
          default: "ptlds_default9",
          cover: "ptlds_cover9",
        },
      ],
      // 典型工程
      dxgcs:[
       {
        cover:require('@/assets/images/productImg/dxgc_1@2x.png')
       },
       {
        cover:require('@/assets/images/productImg/dxgc_2@2x.png')
       },
       {
        cover:require('@/assets/images/productImg/dxgc_3@2x.png')
       }
      ]
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.szhjsgl {
  padding-bottom: 171px;
  background-color: #fff;
  .z_b_img {
    width: 100%;
    height: 400px;
  }
  .ptjs {
    // height: 1010px;
    margin-top: -3px;
    background-color: #fff;

    .p_con {
      width: 1200px;
      padding-top: 64px;
      margin: auto;

      .p_descript {
        margin-top: 18px;
        font-size: 18px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 2px;
        margin-bottom: 64px;
        text-indent:36px;
        text-align: justify;
        color: rgba(29,29,31,0.8);
      }
      .p_dxgc{
        margin-top: 32px;
        margin-bottom: 64px;
        .p_d_item{
          width: 380px;
          height: 214px;
          .p_d_i_img{
            width: 100%;
            height: 100%;
          }
        }
      }
      .p_hxnl {
        margin-top: 32px;
        // padding-bottom: 64px;

        .p_h_item {
          margin-bottom: 80px;
          .p_h_i_left {
            margin-right: 36px;
            .p_h_i_left_img {
              width: 64px;
              height: 64px;
            }
          }
          .p_h_i_right {
            width: 272px;
            // margin-right: 84px;
            .p_h_i_r_con {
              color: rgba(29, 29, 31, 0.6);
              margin-top: 8px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
  .p_ptld {
    padding-top: 47px;
    background: #f8f8f8;
    .p_p_con {
      width: 1200px;
      margin: 32px auto;
      padding-bottom: 34px;

      .p_p_c_item {
        width: 380px;
        height: 240px;
        background-image: url("../../../assets/images/productImg/ptld_bg@2x.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-bottom: 30px;
        position: relative;
        .p_h_i_right {
          .p_h_i_r_icon {
            width: 40px;
            height: 19px;
            position: absolute;
            left: 32px;
            bottom: 17px;
          }
        }
        .p_p_c_item_hover {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: none;
          .p_p_c_item_hover_img {
            width: 100%;
            height: 100%;
          }
          .p_hover_title {
            width: 100%;
            height: 64px;
            position: absolute;
            left: 0;
            bottom: 0;
            // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.13) 15%, rgba(0, 0, 0, 0.8) 100%);
          }
        }
      }
      .p_p_c_item:hover {
        .p_p_c_item_hover {
          display: block;
          -webkit-animation: fadeIn 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
          animation: fadeIn 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        }

        @-webkit-keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
